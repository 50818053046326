.cols {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 0 0 -24px;

  & > * {
    margin: 0 0 0 24px;
    width: calc((100% - 24px) / 2);
  }


  // _count

  &._count_2 {
    @media (max-width: 1550px) {
      display: block;
    }

    & > * {
      width: calc((100% - 48px) / 2);

      @media (max-width: 1550px) {
        width: auto;
        margin-top: 24px;
      }

      @media (max-width: 1279px) {
        margin-top: 16px;
      }
    }
  }

  &._count_3 {
    & > * {
      width: calc((100% - 72px) / 3);
    }
  }

  &._count_4 {
    & > * {
      width: calc((100% - 96px) / 4);
    }
  }

  &._count_5 {
    & > * {
      width: calc((100% - 120px) / 5);
    }
  }

  &._count_6 {
    & > * {
      width: calc((100% - 144px) / 6);
    }
  }


  // _wrap

  &._wrap {
    flex-wrap: wrap;
    margin: -24px 0 0 -24px;

    & > * {
      margin: 24px 0 0 24px;
    }
  }
}
