.list {

  &._mark_dash-blue {
    li {
      padding-left: 28px;

      & + li {
        margin-top: 24px;
      }

      &:before {
        content: '';
        position: relative;
        display: inline-block;
        width: 16px;
        height: 2px;
        margin-right: 12px;
        margin-left: -28px;

        vertical-align: middle;

        background-color: $color_main_blue;

        @media(max-width: 1279px) {
          width: 12px;
          height: 1px;
          margin-right: 8px;
        }
      }
    }
  }

}
