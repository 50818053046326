.menu-social {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-social {
    margin: 6px;

    .icon {
      --icon-social-border-width: 0;
    }
  }
}
