.map-wrap {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  ymaps {
    flex: 0 0 100%;
  }
}
