.cblock-content-head {
  position: relative;
  padding: 48px 60px;
  overflow: hidden;

  // background-color: #ccc;
  border-radius: 25px;
  // border: 3px solid #fff;
  // box-shadow:         0px 3px 15px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 0 10px 35px -20px rgba(0,0,0,0.2);

  @media (max-width: 1279px) {
    border-radius: 0;
  }

  @media (max-width: 767px) {
    padding: 36px;
  }


  &._telemedicine {
    .cblock-content-head__bg {
      background-image: linear-gradient(90deg, rgba(255,255,255,1) 35%, rgba(255,255,255,0.45) 100%),
                        url($image-path + 'cblock-content-head/cblock-content-head__telemedicine.jpg');
      background-position: right top;
    }
  }


  &._tariffs {
    .cblock-content-head__bg {
      background-image: linear-gradient(90deg, rgba(255,255,255,1) 35%, rgba(255,255,255,0.45) 100%),
                        url($image-path + 'cblock-content-head/cblock-content-head__tariffs.jpg');
      background-position: right top;
    }
  }


  &._support {
    .cblock-content-head__bg {
      background-image: linear-gradient(80deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0.45) 100%),
                        url($image-path + 'cblock-content-head/cblock-content-head__suppot.jpg');
      background-position: right top, right -10px top;
      background-size: contain;
    }
  }


  &._main {
    .cblock-content-head__bg {
      background-image: linear-gradient(80deg, rgba(255,255,255,1) 45%, rgba(255,255,255,0.45) 100%),
                        url($image-path + 'cblock-content-head/cblock-content-head__main.jpg');
      background-position: right top, right -10px top;
      background-size: contain;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: linear-gradient(160deg, rgba(255,255,255,1) 25%, rgba(255,255,255,0.45) 100%),
                      url($image-path + 'cblock-content-head/cblock-content-head__about.jpg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
  }

  &__content {
    position: relative;
    z-index: 5;
  }

  &__header {
    position: relative;
    margin: 0 0 24px 0;
    // padding: 0 0 12px 0;

    color: $color_main_dark;
    // color: #140c3f;
    font-family: $font_montserrat;
    font-size: 60px;
    // text-transform: uppercase;
    font-style: italic;
    font-weight: 900;

    @media (max-width: 1279px) {
      font-size: 42px;
      margin-bottom: 18px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
      font-weight: 800;
    }

    // &:after {
    //   display: none;
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100px;
    //   height: 3px;

    //   background-color: #140c3f;
    //   opacity: 0.7;
    // }
  }

  &__descr {
    max-width: 860px;

    color: $color_main_dark;
    // color: #140c3f;
    font-family: $font_roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.45;

    @media (max-width: 1279px) {
      font-size: 18px;
      font-weight: normal;
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }

    p {
      margin: 12px 0;

      &:first-child {
        margin-top: 0;
      }
    }

    p + ul {
      margin-top: 12px;
    }

    ul + p {
      margin-top: 24px;
    }
  }

  &__btns {
    margin-top: 24px;
  }
}
