.kbd,
kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset;
  color: #333;
  display: inline-block;
  font-family: $font_roboto;
  font-size: 0.9rem;
  line-height: 1;
  padding: 3px 4px 2px 4px;
  white-space: nowrap;
  cursor: default;
}
