.list {
  &._mark_check {
    line-height: 170%;

    li {
      &:before {
        content: '';
        display: inline-block;
        height: 1em;
        width: 1em;
        padding-left: 1.6em;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTS0xLTFoMTh2MThILTF6Ii8+PGc+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMjRjNzY5IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTE1IDNMNSAxMyAxIDgiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvZz48L3N2Zz4=');
        background-size: 16px;
        background-repeat: no-repeat;
      }
    }
  }
}
