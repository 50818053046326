
.table {
  display: block;
  width: 100%;
  // max-width: 1000px;

  border-bottom: 3px solid $color_main_dark;

  @media (max-width: 767px) {
    border-width: 2px;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    th, td {
      padding: 0;
      text-align: left;
      border: none;
      line-height: 162%;

      cursor: default;
    }

    tr {
      // border-bottom: 1px solid rgba($color_main_dark, 0.1);

      transition: background-color 300ms ease;

      // &:hover {
      //   background-color: #f0f0f0;

      //   &:first-child {
      //     background-color: transparent;
      //   }
      // }
    }

    th {
      padding: 6px 24px;

      font-family: $font_montserrat;
      font-size: 22px;
      color: $color_main_dark;
      font-weight: bold;

      border: 3px solid $color_main_dark;

      @media (max-width: 767px) {
        padding: 4px 12px;

        font-size: 16px;

        border-width: 2px;
      }
    }

    td {
      padding: 6px 24px;

      font-family: $font_roboto;
      font-size: 20px;
      color: $color_main_dark;

      border: 1px solid rgba($color_main_dark, 0.1);

      &:first-child {
        border-left: 3px solid $color_main_dark;
      }

      &:last-child {
        border-right: 3px solid $color_main_dark;
      }

      @media (max-width: 767px) {
        padding: 4px 12px;

        font-size: 14px;
      }
    }
  }
}
