.layout-main {
  position: relative;
  display: block;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-areas: 'header' 'content' 'footer';
  // grid-gap: 24px;
  max-width: 1600px;
  margin: 0 auto;
  padding: 24px;

  @media (max-width: 1279px) {
    // display: block;
    // grid-gap: 8px;
    padding: 18px 0 0 0;
  }


  &__header {
    position: relative;
    // grid-area: header;
    margin-bottom: 24px;

    @media (max-width: 1279px) {
      margin-bottom: 8px;
    }
  }

  &__content {
    position: relative;
    // grid-area: content;

    .cblock-content-head + .cblock-section,
    .cblock-section + .cblock-section {
      margin-top: 96px;

      @media (max-width: 1279px) {
        margin-top: 72px;
      }

      @media (max-width: 767px) {
        margin-top: 48px;
      }
    }

    .cblock-section:last-child {
      margin-bottom: 48px;
    }
  }

  &__footer {
    position: relative;
    // grid-area: footer;
    align-self: end;
    margin-top: 24px;

    @media (max-width: 1279px) {
      margin-top: 8px;
    }
  }

}
