
//////////
// .btn-link-icon
/////

.btn-link-icon {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;

  text-decoration: none;

  appearance: none;
  border: none;
  background-color: transparent;

  cursor: pointer;

  &:hover {
    .btn-link-icon__caption {
      text-decoration: underline;

      .btn-link-icon__note {
        text-decoration: none;
      }
    }
  }

  .icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 24px;

    @media (max-width: 1279px) {
      height: 16px;
    }

    & + .btn-link-icon__caption {
      margin-left: 12px;
    }
  }

  .icon {
    fill: $color_main_blue;
  }

  &__caption {
    flex: 1 1 100%;

    color: $color_main_blue;
    font-family: $font_roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;

    @media (max-width: 1279px) {
      font-size: 12px;
      font-weight: normal;
    }

    & + .icon-wrap {
      margin-left: 12px;
    }
  }

  &__note {
    display:inline-block;

    color: $color_main_dark;
    font-family: $font_roboto;
    font-size: 16px;
    line-height: 1.5;
    text-decoration: none;

    opacity: 0.5;

    @media (max-width: 1279px) {
      font-size: 12px;
    }
  }


}
