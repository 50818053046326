
//////////
// .btn._block
/////

.btn {

  &._block {
    display: flex;
    width: 100%;
  }


}
