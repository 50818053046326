.list-facts {
  position: relative;

  &__items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: -48px -48px 0 -48px;

    @media (max-width: 767px) {
      margin: -48px 0 0 0;
    }
  }

  &__item {
    flex: 0 0 calc(100% / 3 - 96px);
    margin: 48px 48px 0 48px;

    @media (max-width: 767px) {
      flex: 0 0 100%;
      margin: 24px 0 0 0;
    }
  }

  &._cols {
    &_1 {
      .list-facts__items {
        display: block;
        margin-top: 0;
      }

      .list-facts__icon {
        grid-area: icon;
        margin-bottom: 0;

        .icon-wrap {
          justify-content: center;
          align-items: flex-start;
          width: 80px;
          height: 80px;
          margin-left: 0;

          .icon {
            max-width: 70px;
            max-height: 70px;
          }
        }
      }

      .list-facts__fact {
        grid-area: header;
        margin-left: 12px;

        font-size: 24px;

        @media (max-width: 1279px) {
          margin-left: 0;

          font-size: 20px;
        }
      }

      .list-facts__descr {
        grid-area: descr;
        margin-left: 12px;

        font-size: 20px;

        @media (max-width: 1279px) {
          margin-left: 0;

          font-size: 16px;
        }
      }

      .list-facts__item {
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: 'icon header' 'icon descr';
        grid-gap: 0;
        flex: 0 0 100%;
        max-width: 1000px;
        margin: 48px 48px 0 48px;

        @media (max-width: 1279px) {
          grid-template-columns: 1fr;
          grid-template-rows: auto auto auto;
          grid-template-areas: 'icon' 'header' 'descr';
        }

        @media (max-width: 767px) {
          margin: 24px 0 0 0;
        }
      }
    }
  }

  &._cols {
    &_2 {
      .list-facts__item {
        flex: 0 0 calc(100% / 2 - 96px);
        margin: 48px 48px 0 48px;

        @media (max-width: 767px) {
          flex: 0 0 100%;
          margin: 24px 0 0 0;
        }
      }
    }
  }

  &._cols {
    &_4 {
      .list-facts__item {
        flex: 0 0 calc(100% / 4 - 96px);
        min-width: 220px;
        margin: 48px 48px 0 48px;

        @media (max-width: 1400px) {
          flex: 0 0 calc(100% / 2 - 96px);
        }

        @media (max-width: 767px) {
          flex: 0 0 100%;
          margin: 24px 0 0 0;
        }
      }
    }
  }

  &__icon {
    margin-bottom: 12px;

    .icon-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100px;
      height: 80px;
      margin-left: 12px;

      .icon {
        max-width: 90px;
        max-height: 90px;

        &._size_80 {
          max-width: 80px;
          max-height: 80px;
        }

        &._size_70 {
          max-width: 70px;
          max-height: 70px;
        }

        &._size_60 {
          max-width: 60px;
          max-height: 60px;
        }
      }
    }
  }

  &__fact {
    margin-bottom: 6px;

    font-family: $font_montserrat;
    font-size: 20px;
    color: $color_main_red;
    font-weight: 600;
  }

  &__descr {
    font-family: $font_roboto;
    font-size: 16px;
    color: $color_main_dark;
    font-weight: normal;
    line-height: 1.45;
  }
}
