.heading {
  display: block;
  margin: 0;
  padding: 0;

  color: $color_main_dark;
  font-family: $font_roboto;
  font-weight: bold;
  text-transform: none;

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 1279px) {
      justify-content: space-between;
    }
  }

  &[data-block-title-before] {
    &:before {
      content: attr(data-block-title-before);
      display: block;
      color: $color_main_blue;
      font-family: $font_montserrat;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.5;
    }
  }
}
