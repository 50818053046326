.heading {

  &._type {

    &_page-title {
      font-family: $font_montserrat;
      font-size: 36px;
      font-weight: bold;
      line-height: 1.5;

      @media (max-width: 1279px) {
        font-size: 22px;
        line-height: 1.27;
      }

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }

    &_block-today {
      color: $color_main_blue;
      font-family: $font_montserrat;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.5;

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }

    &_block-title {
      font-family: $font_montserrat;
      font-size: 42px;
      color: $color_main_dark;
      font-weight: 900;
      font-style: italic;

      @media (max-width: 1279px) {
        font-size: 36px;
        font-weight: 800;
      }

      @media (max-width: 767px) {
        font-size: 22px;
        font-style: normal;
      }

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }

    &_block-subtitle {
      font-family: $font_montserrat;
      font-size: 24px;
      font-weight: 800;
      line-height: 1.5;
      font-style: italic;

      @media (max-width: 1279px) {
        font-family: $font_montserrat;
        font-size: 20px;
        line-height: 1.38;
      }

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }

    &_block-service {
      font-family: $font_roboto;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }

    &_footer-title {
      color: $color_main_lighter;
      font-family: $font_roboto;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.5;

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }

    &_banner-term {
      font-family: $font_montserrat;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 1px;

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-transform: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }

    &_banner-article {
      font-family: $font_montserrat;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }

    &_promo-title {
      color: $color_main_blue;
      font-family: $font_montserrat;
      font-size: 36px;
      font-weight: bold;
      line-height: 1.5;

      a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }

  }

}
