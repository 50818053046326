.btn-close {
  display: block;
  position: relative;
  width: 12px;
  height: 12px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: left;
  appearance: none;
  cursor: pointer;
  font-size: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 1.5px);
    left: -1.5px;
    display: block;
    width: 15px;
    height: 2px;

    background-color: #a0a5b5;
    transform: rotate(45deg);

    transition: background-color 300ms ease;
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    &:before,
    &:after {
      background-color: $color_main_blue;
    }
  }

}
