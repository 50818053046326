.head-main {
  position: relative;
  padding: 16px 36px;

  background: rgb(255,68,61);
  background: linear-gradient(175deg, rgba(255,68,61,1) 0%, rgba(210,26,26,1) 100%);
  border-radius: 3px;

  @media (max-width: 1279px) {
    border-radius: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__phone {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: #fff;
    font-size: 28px;
    font-family: $font_roboto;
    font-weight: bold;
    text-decoration: none;

    @media (max-width: 767px) {
      margin-top: 12px;

      font-size: 18px;
    }
  }

}
