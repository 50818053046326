
//////////
// .btn._type
/////

.btn {

  &._type_ghost {
    padding-top: 13px;
    padding-bottom: 13px;
    background-color: transparent;
    border: 2px solid currentColor;
    color: #fff;

    @media (max-width: 1279px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:hover {
      color: #4a9869;
      background-color: #fff;
      border-color: #fff;
      box-shadow: 0 3px 9px 0 rgba(255, 255, 255, 0.3);
    }

    &._color_red {
      color: $color_main_red;

      &:hover {
        color: #fff;
        background-color: $color_main_red;
        border-color: $color_main_red;
      }
    }

    &._color_green {
      color: $color_main_green;

      &:hover {
        color: #fff;
        background-color: $color_main_green;
        border-color: $color_main_green;
      }
    }

  }



  &._type_ghost-response {
    min-width: auto;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 16px;
    padding-left: 16px;

    color: $color_main_dark;
    font-family: $font_roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-transform: none;

    background-color: #fff;
    border: 2px solid #d1e1f4;

    transition: border-width 300ms ease, border-color 300ms ease, box-shadow 300ms ease;

    @media (max-width: 1279px) {
      padding: 4px 8px;

      font-size: 12px;
      line-height: 1.34;
    }

    &:hover {
      color: $color_main_dark;

      background-color: #fff;
      border-width: 2px;
      border-color: $color_main_blue;
      box-shadow: 0 22px 30px -7px rgba($color_main_gray, 0.5);
    }

  }


  &._type {

    &_mobile {
      color: $color_main_dark;
      font-family: $font_roboto;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-transform: none;

      background-color: #fff;
    }

    &_mobile-search {
      color: $color_main_dark;
      font-family: $font_roboto;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-transform: none;

      background-color: $color_main_gray-l;

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 6px;

        fill: $color_main_gray;
      }
    }

  }


}
