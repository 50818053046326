
//////////
// .btn._size
/////

 .btn {

  &._size_micro {
    min-width: auto;
    padding: 0;

    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 1px;

    background-color: transparent;

    .icon {
      width: 14px;
      height: 14px;
      margin-right: 9px;
    }

    @media (max-width: 1279px) {
      padding: 13px 20px;
    }
  }

  &._size_xs {
    padding: 12px 15px;

    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.9px;

    .icon {
      width: 14px;
      height: 14px;
      margin-right: 9px;
    }
  }

  &._size_s {
    padding: 15px 20px;

    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.9px;

    .icon {
      width: 12px;
      height: 12px;
      margin-right: 7px;
    }
  }

  &._size_m {
    padding: 15px 20px;

    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;

    .icon {
      width: 14px;
      height: 14px;
      margin-right: 9px;
    }
  }

  &._size_l {
    padding: 21px 20px;
    // padding: 21px 63px;

    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }

    @media (max-width: 1279px) {
      padding: 13px 20px;

      font-size: 12px;
      font-family: $font_roboto;
      line-height: 1.34;
      letter-spacing: 0.5px;
    }
  }

  &._size_lxl {
    padding: 18px 20px;

    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1.14px;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }

  &._size_xl {
    // padding: 18px 30px;

    // font-weight: 500;
    font-size: 20px;
    // line-height: 1.5;

    // .icon {
    //   width: 20px;
    //   height: 20px;
    //   margin-right: 16px;
    // }
  }

}
