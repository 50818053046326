
//////////
// .btn-link-icon._color
/////

.btn-link-icon {

  &._color {

    &_gray {
      color: $color_main_gray;

      .icon {
        fill: $color_main_gray;
      }

      .btn-link-icon__caption {
        color: $color_main_gray;
      }

    }

    &_red {
      color: $color_main_red;

      .icon {
        fill: $color_main_red;
      }

      .btn-link-icon__caption {
        color: $color_main_red;
      }

    }

    &_green {
      color: $color_main_green;

      .icon {
        fill: $color_main_green;
      }

      .btn-link-icon__caption {
        color: $color_main_green;
      }

    }

  }

}
