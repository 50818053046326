.abbr,
abbr[title] {
  position: relative;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  border: none;
  cursor: help;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    height: 0;
    border-bottom: 1px dotted currentColor;
    opacity: 0.3;
  }
}
