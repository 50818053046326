.breadcrumbs {

  &._color {

    &_light {

      .breadcrumbs__link {
        color: #fff;

        // &:hover {
        //   color: $color_main_dark;
        // }
      }

      .breadcrumbs__separator {
        color: #fff;

        .icon {
          fill: #fff;
        }
      }

    }

  }

}
