.link {

  &._no-underline {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

}
