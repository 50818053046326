.cblock-section {
  position: relative;
  display: block;
  padding: 0 60px;

  @media (max-width: 767px) {
    padding: 0 24px;
  }

  .heading._type_block-title {
    margin-bottom: 36px;

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }

    & + .text-wrap {
      margin-top: -12px;
    }
  }

  &._has-banner {
    min-height: 700px;
    padding-right: 500px;

    @media (max-width: 1279px) {
      min-height: auto;
      padding-right: 60px;
    }

    @media (max-width: 767px) {
      padding-right: 24px;
    }

    .banner-try-free {
      position: absolute;
      top: 0;
      right: 0;

      @media (max-width: 1279px) {
        position: relative;
      }
    }
  }

}
