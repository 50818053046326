.form-group {

  &._disabled {
    .form-group__caption {
      opacity: 0.3;

      label {
        cursor: default;
        pointer-events: none;
      }
    }
  }

}
