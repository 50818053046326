
//////////
// .btn-link-icon._weight
/////

.btn-link-icon {

  &._weight {

    &_normal {
      .btn-link-icon__caption {
        font-weight: normal;
      }
    }

    &_500 {
      .btn-link-icon__caption {
        font-weight: 500;
      }
    }

    &_bold {
      .btn-link-icon__caption {
        font-weight: bold;
      }
    }

  }

}
