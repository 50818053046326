.company-stats {
  position: relative;

  &__header {
    margin: 0 0 36px 0;

    font-family: $font_montserrat;
    font-size: 42px;
    color: $color_main_dark;
    font-weight: 900;
    font-style: italic;

    @media (max-width: 1279px) {
      font-size: 36px;
      font-weight: 800;
    }

    @media (max-width: 767px) {
      font-size: 28px;
    }
  }

  &__items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: -24px -24px 0 -24px;

    @media (max-width: 767px) {
      justify-content: center;
      margin: -12px -12px 0 -12px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 24px 0 24px;

    width: 190px;
    height: 190px;

    background-color: rgba(#fff, 0.5);
    border-radius: 50%;
    box-shadow: 2px 2px 0 #ccc;

    @media (max-width: 1279px) {
      width: 150px;
      height: 150px;
    }

    @media (max-width: 767px) {
      width: 120px;
      height: 120px;
      margin: 12px 12px 0 12px;
    }
  }

  &__value {
    font-family: $font_montserrat;
    font-size: 44px;
    // color: $color_main_red;
    color: $color_main_dark;
    font-weight: 700;
    // text-shadow: 1px 1px 0 #fff;

    @media (max-width: 1279px) {
      font-size: 32px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  &__caption {
    max-width: 170px;

    font-family: $font_montserrat;
    font-size: 18px;
    color: $color_main_dark;
    font-weight: 600;
    text-align: center;
    // text-transform: lowercase;

    @media (max-width: 1279px) {
      margin-top: 4px;

      font-size: 16px;
    }

    @media (max-width: 767px) {
      font-size: 13px;
    }
  }
}
