.list-licenses {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  margin: -12px -60px -48px -60px;
  padding: 12px 60px 48px 60px;

  @media (max-width: 767px) {
    margin: -12px -24px -48px -24px;
    padding: 12px 24px 48px 24px;
  }

  &__items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin: -12px -12px 0 -12px;
  }

  &__item {
    perspective: 1000px;
    margin: 12px 12px 0 12px;
  }

  &__link {
    display: block;
    padding: 6px;

    font-size: 0;

    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 10px 35px -20px rgba(0,0,0,0.2);

    transition: transform 300ms ease, box-shadow 300ms ease;

    &:hover {
      transform: translate3d(0, 0, 45px) rotate3d(1, -1, 0, 10deg);
      box-shadow: 0 10px 20px 0px rgba(0,0,0,0.2);
    }
  }

  &__thumb {
    @media (max-width: 767px) {
      max-width: 200px;
    }
  }
}
