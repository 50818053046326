
//////////
// .btn-social._round
/////

.btn-social {

  &._round {
    overflow: hidden;

    border-radius: 50%;
  }


}
