.list-partners {
  position: relative;

  &__items {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: -48px;
    margin-left: -48px;

    @media (max-width: 767px) {
      margin-top: -24px;
      margin-left: -24px;
    }
  }

  &__item {
    margin: 48px 0 0 48px;

    @media (max-width: 767px) {
      margin: 24px 0 0 24px;
    }
  }

  &__logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 250px;

    @media (max-width: 1279px) {
      width: 200px;
      height: 200px;
    }

    @media (max-width: 767px) {
      width: 110px;
      height: 110px;
    }
  }

  &__logo {
    @media (max-width: 1279px) {
      max-width: 150px;
      max-height: 150px;
    }
    @media (max-width: 767px) {
      max-width: 100px;
      max-height: 100px;
    }
  }
}
