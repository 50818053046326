.select {

  &._size_s {
    font-size: 12px;
    font-family: $font_montserrat;
    font-weight: bold;

    .select__list {
      height: 26px;
      padding: 0 22px 0 8px;
      line-height: 26px;
      background-color: #EBECF0;
      box-shadow: none;

      &:hover,
      &:focus {
        background-color: $color_main_lightest;
        box-shadow: 0 2px 7px 0 #dbdee7;
      }
    }

    .select__icon {
      width: 8px;
      right: 8px;
    }
  }

  &._size_ms {
    font-size: 12px;
    font-family: $font_montserrat;
    font-weight: bold;

    @media (max-width: 1279px) {
      font-family: $font_roboto;
    }

    .select__list {
      height: 48px;
      padding: 0 48px 0 24px;
      line-height: 48px;
      background-color: $color_main_lighter;
      box-shadow: none;

      @media (max-width: 1279px) {
        height: 36px;
        padding: 0 32px 0 12px;

        line-height: 36px;
      }

      &:hover,
      &:focus {
        background-color: $color_main_lighter;
      }
    }

    .select__icon {
      width: 8px;
      right: 24px;

      @media (max-width: 1279px) {
        width: 8px;
        height: 5px;
        right: 12px;
        margin-top: -2px;
      }
    }
  }

  &._size_m {
    font-size: 16px;
    font-family: $font_roboto;
    font-weight: normal;

    @media (max-width: 1279px) {
      font-size: 12px;
    }

    .select__list {
      height: 48px;
      padding: 0 66px 0 24px;
      line-height: 48px;
      background-color: $color_main_lighter;
      box-shadow: none;

      @media (max-width: 1279px) {
        height: 36px;
        padding: 0 32px 0 12px;

        line-height: 36px;
      }

      &:hover,
      &:focus {
        background-color: $color_main_lighter;
      }
    }

    .select__icon {
      // width: 8px;
      right: 28px;

      @media (max-width: 1279px) {
        width: 8px;
        height: 6px;
        right: 12px;
        margin-top: -2px;
      }
    }
  }

  &._size_l {
    font-size: 16px;
    font-family: $font_roboto;
    font-weight: normal;

    .select__list {
      height: 60px;
      line-height: 60px;
    }

    .select__icon {
      right: 26px;
    }
  }

}
