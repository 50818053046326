.menu-footer {
  display: block;
  max-width: 500px;
  margin: 0;
  padding: 0;

  &__items {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    // display: block;
    list-style-type: none;
  }

  &__link {
    position: relative;
    display: inline-block;
    padding: 6px 12px;

    color: $color_main_dark;
    font-family: $font_roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: none;
    // text-transform: uppercase;

    opacity: 1;
    transition: opacity 300ms ease;

    @media (max-width: 1279px) {
      font-size: 14px;
      font-weight: normal;
      // line-height: 1.33;
    }

    &:hover {
      opacity: 0.7;
    }

    &._selected {
      color: $color_main_red;
      font-weight: bold;

      cursor: default;

      &:hover {
        opacity: 1;
      }

      .menu-footer__caption {
        border-bottom: 3px solid currentColor;

        @media (max-width: 1279px) {
          border-width: 2px;
        }
      }
    }

  }

  &__caption {
    display: inline-block;
    padding: 0 1px 0 1px;
    border-bottom: 3px solid transparent;

    @media (max-width: 1279px) {
      border-width: 2px;
    }
  }

}
