
// Main colors

$color_main_dark:     #3a4158;

$color_main_gray:     #a0a5b5;
$color_main_gray-l:   #e8eaf1;
$color_main_light:    #ebecf0;
$color_main_lighter:  #f1f2f5;
$color_main_lightest: #f9f9fb;

$color_main_red:      #e84234;

$color_main_green:    #81a809;

$color_main_blue:     #2a70e2;
$color_main_cyan:     #15bbee;



// Additional colors

$color_controls_off:  #d8d8d8;
$color_controls_on:   $color_main_blue;
