
.icon {
  position: relative;
  transition: opacity 300ms ease, fill 300ms ease, color 300ms ease;

  --icon-social-border-width: 2;
  --icon-other-repeat-width: 3;

  &._disabled {
    opacity: 0.3;
    color: $color_main_dark !important;
  }


  // &._border-width-3 {
  //   --icon-social-border-width: 3;
  // }




  //social

  &._social_fb {
    color: #007FFF;
  }
  &._social_linkedin {
    color: #0082AA;
  }
  &._social_inst {
    color: #B869AA;
  }
  &._social_link {
    color: #2A70E2;
  }
  &._social_mail {
    color: #2A70E2;
  }
  &._social_medium {
    color: #161413;
  }
  &._social_ok {
    color: #FF9802;
  }
  &._social_pinterest {
    color: #F07C7C;
  }
  &._social_reddit {
    color: #FF4500;
  }
  &._social_telegram {
    color: #33A9E4;
  }
  &._social_twitter {
    color: #15BBEE;
  }
  &._social_viber {
    color: #9069AE;
  }
  &._social_vk {
    color: #4a76a8;
  }
  &._social_web {
    color: #2A70E2;
  }
  &._social_whatsapp {
    color: #4CAF50;
  }




  // other

  &._check {
    width: 12px;
    height: 12px;
  }
  &._comment {
    width: 14px;
    height: 13px;
  }
  &._eye {
    width: 20px;
    height: 12px;
  }
  &._flag {
    width: 16px;
    height: 17px;
  }
  &._gear {
    width: 24px;
    height: 24px;
  }
  &._help {
    width: 18px;
    height: 18px;
  }
  &._mail {
    width: 18px;
    height: 12px;
    @media (max-width: 1279px) {
      width: 12px;
      height: 8px;
    }
  }
  &._phone {
    width: 16px;
    height: 16px;
    @media (max-width: 1279px) {
      width: 11px;
      height: 11px;
    }
  }
  &._placemark {
    width: 14px;
    height: 18px;
  }
  &._plus {
    width: 16px;
    height: 16px;
  }


}
