.text {

  &._color,
  &._color_dark {
    color: $color_main_dark;
  }

  &._color_red {
    color: $color_main_red;
  }

  &._color_cyan {
    color: $color_main_cyan;
  }

  &._color_light {
    color: $color_main_lighter;
  }

  &._color_blue {
    color: $color_main_blue;
  }

  &._color_gray {
    color: $color_main_gray;
  }

}
