
//////////
// .btn._shadow
/////

 .btn {

  &._shadow_hover {
    box-shadow: none;

    &:hover {
      box-shadow: 0 3px 9px 0 rgba(56, 132, 255, 0.4);
    }

    &:active {
      box-shadow: 0 3px 9px 0 rgba(56, 132, 255, 0.1);
    }

    &._color_light {
      &:hover {
        box-shadow: 0 3px 9px 0 rgba(56, 132, 255, 0.3);
      }
    }
  }

  &._shadow_permanent {
    box-shadow: 0 20px 30px -10px rgba(0, 0, 0, 0.5);

    &:hover {
      box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.5);
    }

    &:active {
      box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
    }
  }


}
