.faq {

  &__tab {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0;

    & + .faq__tab {
      margin-top: 12px;
    }
  }

  &__checkbox {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 1px;
    z-index: -1;

    opacity: 0.01;

    &:checked {
      & ~ .faq__tab-head {
        .icon {
          transform: rotateX(180deg);
        }
      }

      & ~ .faq__tab-content {
        max-height: none;
        padding: 0 0 12px 0;
      }
    }
  }

  &__tab-head {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0;

    user-select: none;
    cursor: pointer;

    .icon {
      width: 12px;
      height: 6px;
      margin-right: 9px;

      transition: transform 300ms ease;
    }
  }

  &__tab-head-text {
    color: $color_main_dark;
    font-family: $font_roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }


  &__tab-content {
    max-height: 0;
    overflow: hidden;
    padding: 0;

    transition: max-height 0ms linear, padding 0ms linear;

    .checkbox {
      & + .checkbox {
        margin-top: 24px;
      }
    }
  }

}
