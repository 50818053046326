.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: -5px 0 0 0;
  padding: 0;

  font-size: 14px;
  line-height: 20px;

  @media (max-width: 1279px) {
    display: none;
  }

  &__item,
  &__separator {
    list-style: none;
    display: flex;
    align-items: center;

    font-family: $font_roboto;
    font-weight: normal;

    .icon {
      width: 10px;
      height: 9px;
      fill: #a0a5b5;
      transform: rotate(90deg) scale(0.555);
    }
  }

  &__item:last-child {
    margin-left: 8px;
  }

  &__link {
    margin-top: 5px;
    padding: 2px 8px 3px 8px;

    color: $color_main_dark;
    font-family: $font_montserrat;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    text-decoration: none;

    opacity: 0.5;
    border-radius: 10px;
    transition: background-color 300ms ease, color 300ms ease, opacity 300ms ease;

    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
  }

  &__current {
    margin-top: 5px;
    padding: 2px 8px 3px 8px;

    color: $color_main_dark;
    font-family: $font_montserrat;
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    text-decoration: none;

    background-color: $color_main_lightest;
    border-radius: 10px;

    cursor: default;
  }

  &__separator {
    margin: 5px 4px 0 4px;

    color: #a0a5b5;
    cursor: default;
  }

}
