
.btns-social-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -24px;

  .btn-social {
    margin: 24px 0 0 24px;

    @media (max-width: 1279px) {
      margin: 16px 0 0 22px;
    }
  }
}

//////////
// .btn-social
/////

.btn-social {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 33px;
  height: 31px;

  line-height: 31px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;

  appearance: none;
  background-color: transparent;
  border: 0 solid transparent;
  opacity: 1;
  cursor: pointer;
  user-select: none;
  transition: transform 100ms ease, opacity 300ms ease;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.6;
    transform: scale(0.98);
  }

  .icon {
    display: inline-block;
    width: 33px;
    height: 31px;
  }

}
