
//////////
// .btn-social._add
/////

.btn-social {

  &._add {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background-color: $color_main_blue;

    .icon {
      width: 20px;
      height: 20px;

      fill: #fff;
    }
  }


}
