
//////////
// .btn._loading
/////

.btn {

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    opacity: 0;
    background-color: $color_main_blue;

    transition: opacity 300ms ease;

    @keyframes loader-dot {
      0%,
      100% {
          transform: scale(1);
      }
      50% {
        transform: scale(2);
      }
    }

    [class^="btn__loader-dot"] {
      position: relative;
      width: 4px;
      height: 4px;
      margin-left: 10px;

      background-color: #fff;
      border-radius: 50%;

      animation: loader-dot 1s infinite cubic-bezier(0, 0, 0.49, 1.02);


      &:first-child {
        margin-left: 0;
      }
    }

    .btn__loader-dot-1 {
      animation-delay: 200ms;
    }
    .btn__loader-dot-2 {
      animation-delay: 400ms;
    }
    .btn__loader-dot-3 {
      animation-delay: 600ms;
    }
  }

  &._loading {
    color: transparent;

    cursor: default;
    pointer-events: none;

    .btn__loader {
      opacity: 1;
    }
  }

}
