
.js-tabs-content__item:not(._selected) {
  display: none;
}


.office-block {
  position: relative;

  & > .heading {
    margin: 24px 0;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    padding: 12px 0 12px 51px;
    margin-left: 48px;

    @media (max-width: 1279px) {
      flex: 0 0 50%;
      margin-left: 0;
      margin-bottom: 24px;
      padding: 6px 0 6px 36px;
    }

    @media (max-width: 767px) {
      flex: 0 0 100%;
      margin-bottom: 8px;
      padding: 6px 0 6px 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3px;

      background-color: $color_main_red;
      opacity: 0.5;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &:first-child {
      margin-left: 0;
      padding-left: 0;

      @media (max-width: 1279px) {
        padding-left: 36px;
      }

      @media (max-width: 767px) {
        padding-left: 0;
      }

      &::before {
        display: none;

        @media (max-width: 1279px) {
          display: block;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }

  &__item-title {
    margin-bottom: 12px;

    font-family: $font_roboto;
    font-size: 18px;
    font-weight: bold;
    color: $color_main_dark;
    line-height: 1.5;

    @media (max-width: 767px) {
      margin-bottom: 6px;
    }
  }

  &__item-value {
    font-family: $font_roboto;
    font-size: 16px;
    font-weight: normal;
    color: $color_main_dark;
    line-height: 1.5;

    a {
      color: $color_main_dark;
      text-decoration: none;
    }
  }
}
