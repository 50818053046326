
//////////
// .btn._success
/////

.btn {

  &__success {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    opacity: 0;
    background-color: $color_main_blue;

    transition: opacity 300ms ease;

    .icon {
      width: 23px;
      height: 17px;
      fill: #fff;
    }

  }

  &._success {
    color: transparent;

    cursor: default;
    pointer-events: none;

    .btn__success {
      opacity: 1;
    }
  }

}
