.list {
  margin: 11px 0 0 0;
  padding: 0;

  list-style-type: none;

  color: $color_main_dark;
  font-size: 16px;
  font-family: $font_roboto;
  font-weight: normal;
  line-height: 1.5;

  @media(max-width: 1279px) {
    font-size: 12px;
    line-height: 1.34;
  }
}
