
//////////
// .btn-social._block
/////

.btn-social {

  &._block {
    display: flex;

    &._add {
      display: flex;
    }
  }


}
