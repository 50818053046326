.text-wrap {
  &._cols {
    &_2 {
      column-count: 2;
      column-gap: 48px;
      column-fill: balance;

      @media (max-width: 1366px) {
        column-count: 1;
        column-gap: 0;
      }
    }
  }
}
