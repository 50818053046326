*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  position: relative;
  background-color: #f3f3f3;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: linear-gradient(30deg, rgba(190, 12, 12, 0.3), rgba(249, 200, 33, 0.5)), linear-gradient(210deg, rgba(97, 176, 123, 0.6), rgba(127, 84, 183, 0.5));
    opacity: 0.1;
  }

}


._hide-on-mobile {
  @media (max-width: 1279px) {
    display: none !important;
  }
}
._hide-on-desktop {
  @media (min-width: 1280px) {
    display: none !important;
  }
}
