.select {
  &._disabled {
    opacity: 0.5;

    .select__list {
      cursor: default;
    }

    .select__icon {
      fill: $color_main_dark;
    }

    &:hover {
      .select__list {
        background-color: #fff;
      }

      .select__icon {
        fill: $color_main_dark;
      }
    }

  }
}
