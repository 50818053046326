.btn-close {

  &._size_s {
    width: 12px;
    height: 12px;

    &:before,
    &:after {
      width: 15px;
      height: 2px;
    }
  }

  &._size_m {
    width: 16px;
    height: 16px;

    &:before,
    &:after {
      width: 19px;
      height: 3px;
    }
  }

  &._size_l {
    width: 25px;
    height: 25px;
  }

  &._size_xl {
    width: 36px;
    height: 36px;

    &:before,
    &:after {
      width: 44px;
      height: 6px;
      top: calc(50% - 3px);
      left: -4px;
    }
  }

}
