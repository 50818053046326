
//////////
// .btn._disabled
/////

.btn {

  &._disabled {
    opacity: 0.43;
    user-select: none;
    pointer-events: none;
  }


}
