
.tabs-content {
  position: relative;
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  margin: 0 -8px;

  @media (max-width: 767px) {
    margin: 0;
  }


  // kill scrollbars
  &::-webkit-scrollbar { display: none; }
  & { -ms-overflow-style: none; }


  &__items {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    min-width: 100%;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }


  &__item {
    position: relative;
    flex: 0 1 150px;
    // max-width: 100px;
    display: inline-block;
    margin: 0;
    padding: 0;

    list-style-type: none;

    color: #fff;
    font-family: $font_roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.34;

    text-align: center;

    border-bottom: 3px solid rgba(#fff, 0);

    transition: filter 300ms ease, transform 300ms ease;
    user-select: none;
    cursor: pointer;

    filter: grayscale(90%) blur(1px);
    transform: scale(0.95);

    @media (max-width: 767px) {
      flex: 0 1 100px;
    }

    & + .tabs-content__item {
      margin-left: 24px;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    &._selected {
      filter: grayscale(0%) blur(0px);
      transform: scale(1);

      cursor: default;

      // .tabs-content__item-icon {
      //   fill: $color_main_red;
      // }

      .tabs-content__item-caption {
        color: $color_main_red;

        &-txt {
          border-bottom: 3px solid $color_main_red;
        }
      }
    }

    .icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100px;
    }

    &-icon {
      max-width: 60px;
      max-height: 100px;

      transition: fill 300ms ease;
    }

    &-caption {
      margin-top: 0;

      color: $color_main_dark;
      font-family: $font_roboto;
      font-size: 22px;
      font-weight: 500;
      line-height: 1.5;

      transition: color 300ms ease;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      &-txt {
        padding: 0 1px 3px 1px;

        border-bottom: 3px solid transparent;

        transition: border-color 300ms ease;
      }
    }
  }

}
