
//////////
// .btn-social._size
/////

.btn-social {

  &._size {

    &_32 {
      width: 32px;
      height: 32px;

      line-height: 32px;

      .icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        --icon-social-border-width: 3;
      }

      &._add {
        width: 20px;
        height: 20px;
      }
    }

    &_33x31 {
      width: 33px;
      height: 31px;

      line-height: 31px;

      .icon {
        display: inline-block;
        width: 33px;
        height: 31px;
        --icon-social-border-width: 3;
      }

      &._add {
        width: 31px;
        height: 31px;
      }
    }

    &_32 {
      width: 32px;
      height: 32px;

      line-height: 32px;

      .icon {
        display: inline-block;
        width: 32px;
        height: 32px;
        --icon-social-border-width: 3;
      }

      &._add {
        width: 20px;
        height: 20px;
      }
    }

    &_36 {
      width: 36px;
      height: 36px;

      line-height: 36px;

      .icon {
        display: inline-block;
        width: 36px;
        height: 36px;
        --icon-social-border-width: 2;
      }

      &._add {
        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }

    &_48 {
      width: 48px;
      height: 48px;

      line-height: 48px;

      .icon {
        display: inline-block;
        width: 48px;
        height: 48px;
        --icon-social-border-width: 2;
      }

      &._add {
        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }

  }


}
