
// Load fonts

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800i,900i|Roboto:400,400i,500,700,900i|Vollkorn&subset=cyrillic');



// Fonts vars

$font_roboto:     'Roboto', sans-serif;
$font_montserrat: 'Montserrat', sans-serif;
$font_vollkorn:   'Vollkorn', serif;



// Fonts global modificator classes

._font_roboto {
  font-family: $font_roboto !important;
  font-weight: normal !important;
  font-style: normal !important;
}

._font_roboto_italic {
  font-family: $font_roboto !important;
  font-weight: normal !important;
  font-style: italic !important;
}

._font_roboto_medium {
  font-family: $font_roboto !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

._font_roboto_bold {
  font-family: $font_roboto !important;
  font-weight: bold !important;
  font-style: normal !important;
}



._font_montserrat {
  font-family: $font_montserrat !important;
  font-weight: normal !important;
  font-style: normal !important;
}

._font_montserrat_bold {
  font-family: $font_montserrat !important;
  font-weight: bold !important;
  font-style: normal !important;
}



._font_vollkorn {
  font-family: $font_vollkorn !important;
  font-weight: normal !important;
  font-style: normal !important;
}
