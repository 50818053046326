.text {

  &._mark {
    display: inline;
    padding: 2px 7px;
    border-radius: 3px;
    background-color: $color_main_green;
    color: #fff;
  }

}
