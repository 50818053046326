.btn-close {

  &._color {

    &_white {

      &:before,
      &:after {
        background-color: #ccc;
      }

      &:hover {
        &:before,
        &:after {
          background-color: #fff;
        }
      }

    }

  }

}
