.form-group {
  position: relative;
  display: block;

  &._mt24 {
    margin-top: 24px !important;

    @media (max-width: 1279px) {
      margin-top: 24px !important;
    }
  }

  &._hidden {
    display: none;
  }

  & + .form-group {
    margin-top: 24px;

    @media (max-width: 1279px) {
      margin-top: 16px;
    }
  }

  &__caption {
    position: relative;
    display: block;

    color: $color_main_dark;
    font-family: $font_roboto;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;

    @media (max-width: 1279px) {
      font-size: 12px;
      line-height: 1.34;
    }

    label {
      cursor: pointer;
    }
  }

  &__caption + &__content {
    margin-top: 12px;

    @media (max-width: 1279px) {
      margin-top: 8px;
    }
  }

  &__content + &__caption {
    margin-top: 12px;

    @media (max-width: 1279px) {
      margin-top: 8px;
    }
  }

  &__content {

    .dropdown + .review-filter__selected-products {
      @media (max-width: 1279px) {
        margin-top: 12px;
      }
    }

  }

  &._has-captcha {

    .form-group__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      .input {
        // flex: 0 0 calc(50% - 12px);
        margin-right: 12px;
      }
      .captcha {
        flex: 1 1 242px;
      }
    }

  }
}
