.content-wrap {
  display: block;

  .picture {
    display: block;
    margin: 24px 0;
  }

  .table {
    margin: 24px 0;
  }

  .office-block + ymaps,
  .office-block + .map-wrap {
    margin-top: 24px;
  }
}
