.heading {

  &._color {

    &_dark {
      color: $color_main_dark !important;
    }

    &_light {
      color: $color_main_lighter !important;
    }

    &_white {
      color: #fff !important;
    }

    &_blue {
      color: $color_main_blue !important;
    }

    &_cyan {
      color: $color_main_cyan !important;
    }

    &_red {
      color: $color_main_red !important;
    }

    &_gray {
      color: $color_main_gray !important;
    }

  }

}
