
//////////
// .btn._color (old)
// .btn_theme (new)
/////

.btn {

  &._theme {

    // for page_1121_registration-finished
    &_reg-finished {

    }

  }




  &._color_blue {
    color: #fff;
    background-color: $color_main_blue;

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_green {
    color: #fff;
    background-color: $color_main_green;

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_light {
    color: $color_main_blue;
    background-color: $color_main_lightest;

    &:hover {
      background-color: $color_main_lightest;
    }

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_light-dark {
    color: $color_main_dark;
    background-color: $color_main_lighter;

    &:hover {
      background-color: $color_main_lighter;
    }

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_dark {
    color: #fff;
    background-color: $color_main_dark;

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_white {
    color: rgba($color_main_dark, 0.7);
    background-color: #fff;

    &:hover {
      color: $color_main_blue;
    }

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_white_blue {
    color: $color_main_blue;
    background-color: #fff;

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_red {
    color: #fff;
    background-color: $color_main_red;

    &:hover {
      background-color: $color_main_red;
    }

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_transparent {
    color: $color_main_dark;
    background-color: transparent;

    // @media (max-width: 1279px) {
    //   font-weight: normal;
    //   text-transform: none;
    // }

    &:hover {
      color: $color_main_blue;
      background-color: transparent;
    }

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_transparent-white {
    color: #fff;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:active {
      transform: scale(0.99);
    }
  }

  &._color_transparent-blue {
    color: $color_main_blue;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:active {
      transform: scale(0.99);
    }
  }


}
