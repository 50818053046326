.text-wrap {
  display: block;

  color: $color_main_dark;
  font-family: $font_roboto;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;

  // @media (max-width: 1279px) {
  //   font-size: 12px;
  //   line-height: 1.34;
  // }

  a {
    color: $color_main_blue;
  }


  // p,
  // .paragraph {
  //   margin: 1.5em 0;
  //   line-height: 162%;
  // }

}
