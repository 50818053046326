.list-simple {
  display: block;

  &__item {
    margin-top: 2px;
    background-color: #fff;

    &:first-child {
      margin-top: 0;
    }
  }
}
