.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 48px 48px 24px 48px;
  // background-color: $color_main_dark;

  @media (max-width: 767px) {
    padding: 24px;
  }

  &__phone {
    margin-bottom: 12px;

    color: $color_main_dark;
    font-size: 32px;
    font-family: $font_montserrat;
    font-weight: bold;
    letter-spacing: 1px;

    @media (max-width: 1279px) {
      font-size: 28px;
    }

    &-link {
      color: $color_main_dark;
      text-decoration: none;
    }
  }

  &__email {
    margin-bottom: 24px;

    color: $color_main_dark;
    font-size: 24px;
    font-family: $font_montserrat;
    font-weight: 600;

    @media (max-width: 1279px) {
      font-size: 20px;
    }

    &-link {
      color: $color_main_dark;
      text-decoration: none;
    }
  }

  .logo {
    width: 50px;
    height: 20px;
    margin-top: 12px;

    .icon {
      max-height: 100%;
    }
  }

  &__copyright {
    margin-top: 24px;

    font-family: $font_roboto;
    color: $color_main_dark;
    font-size: 14px;
    font-weight: normal;

    opacity: 0.75;
    cursor: default;
  }

}
