.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .head-top {
    margin-bottom: 10px;
  }

  .menu-main {
    margin-top: 12px;
  }
}
