
$color_link_default: $color_main_blue;


.link {
  color: $color_link_default;
  text-decoration: underline;
  transition: color 300ms ease;
  touch-action: manipulation;

  &:hover {
    text-decoration: none;
  }
}
