.banner-try-free {
  perspective: 1000px;
  position: relative;
  display: block;
  width: 400px;

  text-decoration: none;

  @media (max-width: 1279px) {
    width: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    box-shadow: 0 10px 15px -10px rgba(0,0,0,.2);

    transition: transform 300ms ease, box-shadow 300ms ease;

    @media (max-width: 1279px) {
      flex-direction: row;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &:hover {
      box-shadow: 0 10px 30px 0px rgba(0,0,0,0.25);
      transform: translate3d(0, 0, 45px) rotate3d(0, -1, 0, 5deg);

      @media (max-width: 1279px) {
        box-shadow: 0 10px 30px 0px rgba(0,0,0,0.15);
        transform: translate3d(0, 0, 15px) rotate3d(0, -1, 0, 1deg);
      }
    }
  }

  &__part-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 24px;

    background: transparent url($image-path + 'banner-try-free/banner-try-free__bg.jpg') no-repeat center center / cover;

    .banner-try-free__logo {
      img {
        max-width: 50px;
      }
    }

    .banner-try-free__info-1 {
      margin-top: 12px;
      padding: 6px 12px;

      font-family: $font_montserrat;
      font-size: 17px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      line-height: 1.5;

      background-color: #e31e24;

      @media (max-width: 1279px) {
        text-align: center;
      }
    }

    .banner-try-free__info-2 {
      margin-top: 12px;

      font-family: $font_roboto;
      font-size: 20px;
      font-weight: 500;
      color: $color_main_dark;
      line-height: 1.5;
      text-align: center;
    }

    .banner-try-free__note {
      margin-top: 48px;

      font-family: $font_roboto;
      font-size: 16px;
      font-weight: normal;
      color: #fff;
      line-height: 1.5;
      text-align: right;

      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    }

  }

  &__part-ad {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 36px 24px;

    background-color: #e31e24;

    .banner-try-free__hours {
      img {
        width: 100%;
        max-width: 300px;
      }
    }

    .banner-try-free__try-caption {
      margin-top: 24px;

      font-family: $font_roboto;
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      line-height: 1.5;

      @media (max-width: 1279px) {
        text-align: center;
      }
    }

  }

}
