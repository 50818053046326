.menu-main {
  position: relative;
  display: block;
  padding: 0 24px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  &__items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__item {
    display: block;
    list-style-type: none;
  }

  &__link {
    position: relative;
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    white-space: nowrap;

    // @media (max-width: 1279px) {
    //   height: 36px;
    //   padding: 0 0 0 52px;
    // }

    &._selected {
      cursor: default;

      .menu-main__caption {
        color: #d31a1a;
        font-weight: bold;

        border-bottom: 3px solid rgba(#d31a1a, 0.7) !important;

        @media (max-width: 1279px) {
          border-width: 2px !important;
        }
      }
    }

    &:hover {
      .menu-main__caption {
        border-bottom: 3px solid rgba($color_main_dark, 0.7);

        @media (max-width: 1279px) {
          border-width: 2px;
        }
      }
    }
  }

  &__caption {
    display: inline-block;
    padding: 0 1px 3px 1px;

    color: $color_main_dark;
    font-family: $font_roboto;
    font-size: 18px;
    font-weight: 500;

    border-bottom: 3px solid transparent;

    transition: color 300ms ease, border-color 300ms ease;

    @media (max-width: 1279px) {
      border-width: 2px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }


}
