
.select {
  position: relative;
  display: inline-block;
  font-family: $font_roboto;
  font-weight: normal;
  font-size: 16px;
  color: $color_main_dark;

  &__list {
    display: block;
    width: 100%;
    height: 60px;
    position: relative;
    padding: 0 64px 0 30px;
    appearance: none;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: 60px;
    background-color: #fff;
    border: none;
    border-radius: $border-radius;
    box-shadow: 0 2px 7px 0 #dbdee7;
    cursor: pointer;
    transition: background-color 300ms ease, box-shadow 300ms ease;

    &::-ms-expand {
      display: none;
    }
  }

  &__icon {
    display: inline;
    width: 10px;
    height: 8px;
    position: absolute;
    top: 50%;
    right: 26px;
    margin-top: -4px;
    transform: rotate(180deg);
    fill: currentColor;
    pointer-events: none;
    transition: fill 300ms ease;
  }

  &:hover {
    .select__list {
      background-color: $color_main_lightest;
    }

    .select__icon {
      fill: $color_main_dark;
    }
  }

  &__list:focus {
    background-color: $color_main_lightest;

    & + .select__icon {
      fill: $color_main_dark;
    }
  }


}
