
//////////
// .btn
/////

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  vertical-align: top;
  // padding: 15px 20px;
  padding: 0 1px;

  font-family: $font_roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;

  color: $color_main_dark;
  text-decoration: none;
  text-align: center;
  // text-transform: uppercase;

  appearance: none;
  background-color: transparent;
  border: 0 solid transparent;
  border-bottom: 3px solid $color_main_green;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  transition: transform 100ms ease, box-shadow 300ms ease, background-color 300ms ease, color 300ms ease, border-color 300ms ease, opacity 300ms ease;

  // @media (max-width: 1279px) {
  //   padding: 12px 20px;

  //   font-family: $font_roboto;
  //   font-size: 12px;
  //   letter-spacing: 0.5px;
  // }

  &:hover {
    color: $color_main_green;
    // box-shadow: 0 3px 9px 0 rgba(56, 132, 255, 0.4);
    box-shadow: none;
  }

  &:active {
    // box-shadow: 0 3px 9px 0 rgba(56, 132, 255, 0.1);
    box-shadow: none;
    transform: scale(0.99);
  }

  .icon {
    display: inline;
    width: 16px;
    height: 16px;
    margin-right: 8px;

    fill: currentColor;
  }

}
