.menu-head-top {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__item {
    margin-left: 24px;

    .btn-link-icon {
      padding: 0 1px 3px 1px;

      border-bottom: 3px solid transparent;

      transition: border-color 300ms ease;

      @media (max-width: 1279px) {
        border-width: 2px;
      }

      &:hover {
        border-bottom: 3px solid currentColor;

        @media (max-width: 1279px) {
          border-width: 2px;
        }

        .btn-link-icon__caption {
          text-decoration: none;
        }
      }

      &._selected {
        border-bottom: 3px solid currentColor;

        @media (max-width: 1279px) {
          border-width: 2px;
        }

        .btn-link-icon__caption {
          font-weight: bold;
        }

        &:hover {
          .btn-link-icon__caption {
            text-decoration: none;
          }
        }
      }

    }

  }
}
