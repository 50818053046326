.logo {
  display: block;
  width: 260px;
  height: 60px;

  font-size: 0;

  @media (max-width: 767px) {
    width: 100%;
    max-width: 200px;
    height: auto;
  }

  .icon {
    max-width: 100%;
    max-height: 60px;

    fill: #fff;
  }

  // @media (max-width: 1279px) {
  //   width: 100%;
  //   height: 44px;
  //   background-color: $color_main_red;
  // }
}
